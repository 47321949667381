export const environment = {
    production: true,
    API_URL: 'https://api-ploi.foodchecker.com.br/api/',
    API_URL_WORKER: 'https://worker-ploi.foodchecker.com.br/api/',
    ROOT_DOMAIN: 'foodchecker.com.br',
    VERSION_CHECK_URL: 'https://app.foodchecker.com.br/version.json',
    FACEBOOK_CLIENT_ID: '1254472225768048',
    GOOGLE_KEY: 'AIzaSyANnrOT-kQfI8RSiKvZtWirAHCMWdrLYqg',
    GOOGLE_CLIENT_ID: '428378910987-gkcsc3gsosed7i4fosdl1vg6kjbk7lik.apps.googleusercontent.com',
    GOOGLE_RECAPTCHA_KEY: '6LeET6wUAAAAAMTPl9J06Bq1xP-LtGgwW1eNOHLs',
    IUGU_ACCOUNT_ID: '5242B68AA5FE4D45889C1063DC350377',
    IUGU_SANDBOX: false,
    BUGSNAG_API_KEY: 'ff44ed4604f8d8e0d46799bccf217b46',
    SENTRY_DSN: 'https://ca5e613efff2aae58dcdf059207e2906@o4509080723783680.ingest.us.sentry.io/4509080726994944',
};
